import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProductService } from './services/product.service';
import { BrokerService } from './services/broker.service';
import { SesionService } from './services/sesion.service';
import { environment } from '../environments/environment';
import { datadogRum } from '@datadog/browser-rum'

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
      public product: ProductService,
      public router: Router,
      public sesion: SesionService,
      public broker: BrokerService) {
        // GTM
        if (environment.gtmPruebas) {

          const script = document.createElement('script');
          script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5H395HG');`;
          document.head.appendChild(script);

          const noscript = document.createElement('noscript');
          noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5H395HG"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.appendChild(noscript);

        } else {

          const script = document.createElement('script');
          script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KJMJT32');`;
          document.head.appendChild(script);

          const noscript = document.createElement('noscript');
          noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KJMJT32"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.appendChild(noscript);
        }


        if (environment.production) {
          // tslint:disable-next-line: only-arrow-functions
          $(document).on('contextmenu', function(e:any) {
            e.preventDefault();
          });
          // tslint:disable-next-line: only-arrow-functions
          $(document).keydown((event:any) => {
            if (event.keyCode === 123) { // Prevent F12
              return false;
            } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) { // Prevent Ctrl+Shift+I
              return false;
            }
            return true;
          });
        }

        this.setRum();
    }

    setRum(){
      this.broker.loadParameters('INIT').subscribe(
        {
        next: (data:any) => {
          if(environment.datadog){
            let client = data.parameters.DdAppId;
            let token = data.parameters.DdCT;
            datadogRum.init({
              applicationId: client,
              clientToken: token,
              // `site` refers to the Datadog site parameter of your organization
              // see https://docs.datadoghq.com/getting_started/site/
              site: 'datadoghq.com',
              service: 'prod-midc',
              env: 'prod',
              // Specify a version number to identify the deployed version of your application in Datadog
              // version: '1.0.0',
              sessionSampleRate: 100,
              sessionReplaySampleRate: 20,
              defaultPrivacyLevel: 'mask-user-input',
          });
          }
        },
        error: (error:any) => {
        }
        })
    }

}
